import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				HarborView Realty
			</title>
			<meta name={"description"} content={"Odblokuj swój wymarzony dom dzięki wiedzy i uczciwości"} />
			<meta property={"og:title"} content={"HarborView Realty"} />
			<meta property={"og:description"} content={"Odblokuj swój wymarzony dom dzięki wiedzy i uczciwości"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08:38:33.655Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08:38:33.655Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08:38:33.655Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08:38:33.655Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08:38:33.655Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08:38:33.655Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08:38:33.655Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				padding="80px 72px 80px 72px"
				background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/Image%20%289%29.png?v=2021-08-26T14:56:08.732Z) 0% 0% /cover no-repeat scroll padding-box"
				border-radius="24px"
				margin="0px 0px 64px 0px"
				sm-padding="60px 36px 60px 36px"
			>
				<Box
					width="60%"
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Witamy w HarborView Realty
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--greyD3" md-text-align="center">
						Witamy w pierwszym kroku w kierunku znalezienia idealnego domu. W HarborView Realty dokładamy wszelkich starań, aby zapewnić Ci wyjątkową obsługę i dogłębną wiedzę rynkową, aby pomóc Ci poruszać się po złożonym świecie nieruchomości. Niezależnie od tego, czy kupujesz swój pierwszy dom, czy szukasz lukratywnej nieruchomości inwestycyjnej, nasz oddany zespół jest tutaj, aby poprowadzić Cię na każdym kroku.
					</Text>
				</Box>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-indigo"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						1
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Doświadczenie na rynku lokalnym
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Mamy niezrównaną wiedzę na temat krajobrazu nieruchomości, co oznacza lepszy wgląd w Twoją inwestycję.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-indigo"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						2
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Spersonalizowana usługa
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Podróż każdego klienta jest wyjątkowa. Dostosowujemy nasze usługi do konkretnych celów związanych z nieruchomościami.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-indigo"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						3
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Zaufana sieć
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Nasze wieloletnie powiązania branżowe zapewniają dostęp do najlepszych nieruchomości, często zanim trafią one na rynek.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-indigo"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						4
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Efektywne procesy
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Upraszczamy złożoność kupna i sprzedaży, ułatwiając Ci osiągnięcie celów związanych z nieruchomościami.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					O nas
				</Text>
				<Text font="--lead" color="--darkL2" max-width="600px">
					W HarborView Realty wierzymy w budowanie trwałych relacji z naszymi klientami. Dążymy do utrzymywania najwyższych standardów uczciwości i profesjonalizmu. Nasze podejście jest proste – na pierwszym miejscu stawiamy interesy naszych klientów.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="center"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08:38:33.655Z"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="contain"
						lg-max-height="392px"
						sm-max-height="213px"
						srcSet="https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08%3A38%3A33.655Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08%3A38%3A33.655Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08%3A38%3A33.655Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08%3A38%3A33.655Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08%3A38%3A33.655Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08%3A38%3A33.655Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08%3A38%3A33.655Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							HarborView Realty wyróżnia się w branży nieruchomości ponad dwudziestoletnim doświadczeniem. Nasz zespół profesjonalnych agentów jest wyposażony w najnowocześniejsze narzędzia i dogłębnie rozumie trendy rynkowe, aby oferować spersonalizowaną obsługę, która spełni Twoje unikalne potrzeby. Naszą misją jest zapewnienie naszym klientom jak najbardziej płynnego i bezstresowego procesu zakupu i sprzedaży.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Components.Faq />
		<Components.Footer>
			<Override slot="link">
				Strona główna
			</Override>
			<Override slot="link1">
				Usługi
			</Override>
			<Override slot="link2">
				Łączność
			</Override>
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac377f0cb1d0025f631c9"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});